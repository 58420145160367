import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import app from "./config/firebase.js";
import CheckinsDataGrid from './CheckinsDataGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  content: {
    padding: 40,
  }
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  const signOut = () => {
    localStorage.clear();
    app.auth().signOut();
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            1Mile Device Report
          </Typography>
          <Button color="inherit" onClick={signOut}>Deslogar</Button>
        </Toolbar>
      </AppBar>

      <div className={classes.content}>
        <Typography variant="h5" gutterBottom>
          Checkins
        </Typography>
        <CheckinsDataGrid/>
      </div>
    </div>
  );
}
