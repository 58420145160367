import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import DateFnsUtils from '@date-io/date-fns';
import app from "./config/firebase.js";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import * as moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const columns = [
  { field: 'grupo', headerName: 'Grupo'},
  { field: 'unidade', headerName: 'Unidade', width: 120},
  { field: 'data', headerName: 'Data', width: 120},
  { field: 'hora', headerName: 'Hora'},
  { field: 'device', headerName: 'Device'},
  { field: 'linha_principal', headerName: 'Linha Principal', width: 120},
  { field: 'linha_secundaria', headerName: 'Linha Secundária', width: 120},
  { field: 'person_id', headerName: 'ID Cartão'},
  { field: 'pessoa', headerName: 'Nome Cartão', width: 280},
  { field: 'tag', headerName: 'Número Cartão', width: 120},
];

const api = process.env.REACT_APP_FUNCTION_API;

const useStyles = makeStyles((theme) => ({
  form: {
    paddingBottom: 30,
  },
  formItem: {
    marginRight: 30,
    minWidth: 260,
  },
  button: {
    marginRight: 20,
    marginBottom: 6,
  }
}));

export default function CheckinsDataGrid() {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [token, setToken] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(1, 'day').toDate());
  const [selectedEndDate, setSelectedEndDate] = useState(moment().subtract(1, 'day').toDate());
  const [page, setPage] = React.useState(1);
  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const pageSize = 8;
  const organizationId = 19;

  const getParams = () => {
    return {
      page,
      limit: pageSize,
      startDate: moment(selectedStartDate).format('DD-MM-YYYY'),
      endDate: moment(selectedEndDate).format('DD-MM-YYYY'),
      organizationId,
      q: query,
    };
  }

  const fetchServerRows = () => {
    if (!localStorage.getItem('token')) {
      return;
    }

    setLoading(true);

    const params = getParams();

    return fetch(
      `${api}/checkins?startDate=${params.startDate}&endDate=${params.endDate}&organizationId=${params.organizationId}&q=${params.q}`,
      {
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      }
      )
      .then(res => {
        if (res.status === 403) {
          setOpen(true);
        }

        return res.json();
      })
      .then(
        (result) => {
          setRows(result.map((element, index) => {
            element.id = index + 1;
            element.data = moment.parseZone(element.data).format("DD/MM/YYYY");
            return element;
          }));
          setRowCount(result.length);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
  }

  const reportCheckins = (format) => {
    if (!localStorage.getItem('token')) {
      return;
    }

    setLoading(true);

    const params = getParams();

    let url = `${api}/report?startDate=${params.startDate}&endDate=${params.endDate}&organizationId=${params.organizationId}`;
    url = params.q ? `${url}&q=${params.q}` : `${url}&q=`;
    url = `${url}&format=${format}`;

    return fetch(
      url,
      {
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
      .then(res => {
        if (res.status === 403) {
          setOpen(true);
        }

        setLoading(false);

        res.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `checkins-${moment.parseZone().format("DD-MM-YYYY-HH-mm")}.${format === 'pdf' ? format : 'xlsx'}`;
          a.click();
        });
      });
  }

  const handleUpdateData = async () => {
    fetchServerRows();
  };

  useEffect(() => {
    setTimeout(() => {
      handleUpdateData();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const reportCheckinsPdf = () => {
    reportCheckins('pdf');
  };

  const reportCheckinsXls = () => {
    reportCheckins('xls');
  };

  const signOut = () => {
    localStorage.clear();
    app.auth().signOut();
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        message="Refaça seu login"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={signOut}>
              DESLOGAR
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <div className={classes.form}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
          >
            <KeyboardDatePicker
              className={classes.formItem}
              margin="normal"
              id="date-picker-dialog-1"
              label="Data início"
              format="dd/MM/yyyy"
              value={selectedStartDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              className={classes.formItem}
              margin="normal"
              id="date-picker-dialog-2"
              label="Data fim"
              format="dd/MM/yyyy"
              value={selectedEndDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <TextField
              className={classes.formItem}
              margin="normal"
              id="query"
              onChange={handleChange}
              label="Nome, Unidade, Grupo, Device"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <Button
              size="medium"
              variant="contained"
              color="primary"
              className={classes.button}
              disableElevation
              onClick={handleUpdateData}>
              Atualizar
            </Button>
            <Button
              variant="outlined"
              color="primary"
              href="#outlined-buttons"
              className={classes.button}
              onClick={reportCheckinsPdf}
            >
              PDF
            </Button>
            <Button
              variant="outlined"
              color="primary"
              href="#outlined-buttons"
              className={classes.button}
              onClick={reportCheckinsXls}
            >
              XLS
            </Button>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>

      <div style={{ minHeight: 540, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageChange={handlePageChange}
          page={page}
          rowCount={rowCount}
          // paginationMode="server"
          pagination
          loading={loading}
        />
      </div>
    </div>
  );
}
